<template>
  <div class="col-xl-12 pr-4">
    <!--  <div @click="toggleOpen = !toggleOpen" class="hover-light">
      <container-headline
        :headline="$t('global.content') + ' ' + this.lang"
        :col="3"
        ><div v-if="toggleOpen === true" class="ml-n5">
          <font-awesome-icon icon="fa-solid fa-chevron-up" />
        </div>
        <div v-if="toggleOpen === false" class="ml-n5">
          <font-awesome-icon icon="fa-solid fa-chevron-down" /></div
      ></container-headline>
    </div> -->
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-4">
              <div class="row">
                <div
                  class="col-xl-12"
                  v-if="!korrespondenz.isMassKorrespondenz"
                >
                  <div class="form-group">
                    <textarea
                      v-model.trim="korrespondenz.korrespondenzadresse"
                      class="form-control"
                      rows="5"
                      :readonly="!editable"
                    ></textarea>
                    <label>{{ $t("global.correspondenceaddress") }}</label>
                  </div>
                </div>
                <div class="col-xl-12">
                  <div class="form-group">
                    <ValidationProvider
                      rules="required"
                      immediate
                      v-slot="{ passed }"
                    >
                      <input
                        v-if="!korrespondenz.versand.isVorlage"
                        v-model.trim="korrespondenz.versand.betreff"
                        v-bind:class="{ 'border-danger': !passed }"
                        class="form-control"
                        :readonly="!editable"
                        placeholder=" "
                      />

                      <input
                        v-if="
                          korrespondenz.versand.isVorlage &&
                          korrespondenz.versand.translation &&
                          lang
                        "
                        v-model.trim="
                          korrespondenz.versand.translation[lang.kuerzel]
                            .betreff
                        "
                        v-bind:class="{ 'border-danger': !passed }"
                        class="form-control"
                        :readonly="!editable"
                        placeholder=" "
                      />
                      <label v-bind:class="{ 'text-danger': !passed }">{{
                        $t("global.subject")
                      }}</label>
                    </ValidationProvider>
                  </div>
                </div>
                <div
                  class="col-xl-12"
                  v-if="!korrespondenz.isMassKorrespondenz"
                >
                  <div class="form-group">
                    <input
                      v-model.trim="korrespondenz.anrede"
                      class="form-control"
                      :readonly="!editable"
                      placeholder=" "
                    />
                    <label>{{ $t("global.salutation") }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4">
              <div class="row">
                <div class="col-xl-12">
                  <div class="form-group">
                    <input
                      v-model.trim="korrespondenz.versand.ort"
                      class="form-control"
                      :readonly="!editable"
                      placeholder=" "
                    />
                    <label>{{ $t("global.city") }}</label>
                  </div>
                </div>
                <div class="col-xl-12">
                  <div class="form-group">
                    <date-picker
                      date-format="dd.mm.yy"
                      :initial="korrespondenz.versand.datum"
                      placeholder=" "
                      :disabled="!editable"
                      :show-icon="true"
                      @update="(val) => (korrespondenz.versand.datum = val)"
                    />
                    <label>{{ $t("global.date") }}</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-4">
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <v-select
                      v-model="unterschrift1"
                      :options="unterschriften"
                      :disabled="!editable"
                      @input="unterschrift1Updated"
                      :placeholder="$t('global.signature')"
                      :getOptionLabel="
                        (unterschrift) => unterschrift.person.personName
                      "
                    >
                      <template #option="{ person }">
                        {{ person.personName }}
                      </template>
                      <template #selected-option="{ person }">
                        {{
                          person.id ? person.personName : $t("global.notlinked")
                        }}
                      </template>
                      <span slot="no-options">{{
                        $t("global.taptosearch")
                      }}</span>
                    </v-select>
                    <label>{{ $t("global.signature1") }}</label>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <v-select
                      v-model="unterschrift2"
                      :options="unterschriften"
                      :disabled="!editable"
                      @input="unterschrift2Updated"
                      :placeholder="$t('global.signature')"
                      :getOptionLabel="
                        (unterschrift) => unterschrift.person.personName
                      "
                    >
                      <template #option="{ person }">
                        {{ person.personName }}
                      </template>
                      <template #selected-option="{ person }">
                        {{
                          person.id ? person.personName : $t("global.notlinked")
                        }}
                      </template>
                      <span slot="no-options">{{
                        $t("global.taptosearch")
                      }}</span>
                    </v-select>
                    <label>{{ $t("global.signature2") }}</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12">
                  <div class="form-group">
                    <textarea
                      v-model.trim="korrespondenz.versand.beilagen"
                      class="form-control"
                      :readonly="!editable"
                      placeholder=" "
                    />
                    <label>{{ $t("global.Attachments") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ENDE Korrespondenzversand -->

          <div class="row" v-if="!korrespondenz.versand.isVorlage">
            <div class="col-xl-12">
              <div class="form-group">
                <ckeditor
                  :editor="editor"
                  v-model="korrespondenz.versand.inhalt"
                  :disabled="!editable"
                  @blur="highlightTextcodes"
                ></ckeditor>
              </div>
            </div>
          </div>

          <div
            class="row"
            v-if="
              korrespondenz.versand.isVorlage &&
              korrespondenz.versand.translation &&
              lang
            "
          >
            <div class="col-xl-12">
              <div class="form-group">
                <ckeditor
                  :editor="editor"
                  v-model.trim="
                    korrespondenz.versand.translation[lang.kuerzel].text
                  "
                  :disabled="!editable"
                  @blur="highlightTextcodes"
                ></ckeditor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import ContainerHeadline from "@/components/ContainerHeadline";
import store from "@/store";
import DatePicker from "@/components/Datepicker";

import ClassicEditor from "/custom_ckeditor/build/ckeditor";

import { LADE_AUSWAHLWERTE_KORRESPONDENZ } from "@/store/korrespondenz/actions.type";

export default {
  name: "KorrespondenzInhalt",
  components: {
    //ContainerHeadline,
    DatePicker,
  },
  metaInfo() {},
  mixins: [],
  store,
  props: {
    korrespondenz: {
      type: Object,
      required: true,
    },
    lang: {
      type: Object,
      required: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      unterschrift1: { person: { personName: this.$t("global.notlinked") } },
      unterschrift2: { person: { personName: this.$t("global.notlinked") } },
      editor: ClassicEditor,
    };
  },
  computed: {
    unterschriften: {
      get() {
        return this.$store.state.korrespondenz.unterschriften;
      },
    },
  },
  watch: {
    korrespondenz: {
      deep: true,
      handler(val) {
        if (
          val.versand &&
          val.versand.unterschrift1 &&
          val.versand.unterschrift1.person
        ) {
          this.unterschrift1 = val.versand.unterschrift1;
        } else {
          this.unterschrift1 = {
            person: { personName: this.$t("global.notlinked") },
          };
        }

        if (
          val.versand &&
          val.versand.unterschrift2 &&
          val.versand.unterschrift2.person
        ) {
          this.unterschrift2 = val.versand.unterschrift2;
        } else {
          this.unterschrift2 = {
            person: { personName: this.$t("global.notlinked") },
          };
        }
      },
    },
  },

  created() {
    if (
      this.korrespondenz.versand &&
      this.korrespondenz.versand.unterschrift1 &&
      this.korrespondenz.versand.unterschrift1.person
    ) {
      this.unterschrift1 = this.korrespondenz.versand.unterschrift1;
    } else {
      this.unterschrift1 = {
        person: { personName: this.$t("global.notlinked") },
      };
    }

    if (
      this.korrespondenz.versand &&
      this.korrespondenz.versand.unterschrift2 &&
      this.korrespondenz.versand.unterschrift2.person
    ) {
      this.unterschrift2 = this.korrespondenz.versand.unterschrift2;
    } else {
      this.unterschrift2 = {
        person: { personName: this.$t("global.notlinked") },
      };
    }
  },

  mounted: function () {
    if (this.unterschriften.length == 0)
      this.$store.dispatch(`korrespondenz/${LADE_AUSWAHLWERTE_KORRESPONDENZ}`);
  },

  methods: {
    highlightTextcodes(event, editor) {
      const pattern = /(@\[([^\]]+)\])|(\{([^}]+)\})/g;

      editor.setData(
        editor.getData().replace(pattern, (match, p1, p2, p3, p4) => {
          const highlightedText = p1 || p3;
          return highlightedText
            ? `<span style="color:#5299e0;">${highlightedText}</span>`
            : match;
        })
      );
    },

    unterschrift1Updated(val) {
      if (val && val.id) this.$emit("unterschrift1updated", val);
      else this.$emit("unterschrift1updated", null);
    },

    unterschrift2Updated(val) {
      if (val && val.id) this.$emit("unterschrift2updated", val);
      else this.$emit("unterschrift2updated", null);
    },
  },
};
</script>

<style></style>
