import Questionaire from "@/pages/Questionaire/Questionaire";
import QuestionaireListe from "@/pages/Dashboardlisten/Questionaireliste";
import TemplateQuestionaire from "@/pages/Questionaire/TemplateQuestionaire";
import TemplateQuestionaireListe from "@/pages/Dashboardlisten/TemplateQuestionaireliste";

export default [
  /*** Questionaire ***/
  {
    path: "/Questionaireliste",
    name: "questionaire-liste",
    component: QuestionaireListe
  },
  {
    path: "/questionaire/:id?/:anzeige",
    name: "questionaire",
    component: Questionaire,
    props: route => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a
      };
    }
  },
  {
    path: "/Templatequestionaireliste",
    name: "template-questionaire-liste",
    component: TemplateQuestionaireListe
  },
  {
    path: "/templateQuestionaire/:id?/:anzeige",
    name: "template-questionaire",
    component: TemplateQuestionaire,
    props: route => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a
      };
    }
  }
];
