<template>
  <div class="list">
    <navbar :docsName="docsName" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.bezeichnung"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.kuerzel"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.shortdesignation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.veranstaltungstatus"
                        :options="veranstaltungsstati"
                        :reduce="(vsl) => vsl.id"
                        label="bezeichnung"
                        :placeholder="$t('global.statusclass')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.nostatusofclassesfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.statusevent") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.semester"
                        :options="periode"
                        :reduce="(p) => p.id"
                        label="bezeichnung"
                        :placeholder="$t('global.period')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.noperiodsfound")
                        }}</span>
                        <template v-slot:option="option">
                          {{ option.bezeichnung }} ({{ option.startdatum }}-{{
                            option.enddatum
                          }})
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          {{ option.bezeichnung }} ({{ option.startdatum }}-{{
                            option.enddatum
                          }})
                        </template>
                      </v-select>
                      <label>{{ $t("global.period") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.subjectslist')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
              :gesamtanzahl="gesamtanzahl"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div class="ml-4">
                    <massenmutation-button
                      :selectedIds="this.selectedIds"
                      :propEntities="propEntities"
                      @mutated="refresh"
                    />
                  </div>
                  <div class="mr-4">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.b_bildung_fach.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="openFach"
                    thead-tr-class="pointer"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td
                        class="no-pointer"
                        v-for="field in fields"
                        :key="field.key"
                      >
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                    <template v-slot:cell(periode)="data">
                      <div v-if="data.value">
                        {{ data.value.bezeichnung }} ({{
                          data.value.startdatum
                        }}-{{ data.value.enddatum }})
                      </div>
                    </template>
                  </b-table>

                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="fach-loeschen-modal"
      @confirmed="fachloeschen"
      :multiple="true"
    />

    <messagebox-count
      :headerText="$t('global.eventsdeleted')"
      id="count-delete-alert-msgbox"
      :ok="true"
      :cancel="false"
      :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
    />

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import HeadMenuFilter from "@/components/HeadMenuFilter";
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";

import store from "@/store";
import Api from "@/Api";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";

import MassenmutationButton from "@/components/Buttons/MassenmutationButton";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

import LoeschenModal from "@/components/Modals/LoeschenModal";

import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";

export default {
  name: "faecher-liste",
  components: {
    HeadMenuFilter,
    Navbar,
    HeadMenu,
    LoeschenModal,
    MassenmutationButton,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.dashboard_subjects");
      },
    };
  },
  mixins: [page, dashboard, filter],
  props: {},
  store,
  data() {
    return {
      verantwortliche: [],
      propEntities: [
        {
          tbl_name: "bxc_veranstaltungen",
          selectedIds: [],
        },
      ],
      sortBy: "bezeichnung",
      sortDesc: false,
      filterRollen: this.$CONST("ROLLEN").DOZENTEN,
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "kuerzel",
          sortable: true,
          label: this.$t("global.shortdesignation"),
        },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
        {
          key: "periode",
          searchable: false,
          sortable: true,
          label: this.$t("global.period"),
        },
        {
          key: "veranstaltungsstatus.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.status"),
        },
      ];
    },
    queryParams: function () {
      var params = {
        veranstaltungstyp: this.$CONST("VERANSTALTUNGSTYPEN").MODULE,
      };

      params.filterBezeichnung = this.setQueryParam(this.filters.bezeichnung);
      params.filterKurzbezeichnung = this.setQueryParam(this.filters.kuerzel);
      params.status = this.setQueryParamList(this.filters.veranstaltungstatus);
      params.verantwortlicher = this.setQueryParam(
        this.filters.verantwortlicher?.id
      );
      params.periode = this.setQueryParamList(this.filters.semester);

      //TODO:

      //params.filterPBez = this.filters['periode.bezeichnung']
      //params.filterVName = this.filters['verantwortlicher.personName']
      //params.filterVSBeZ = this.filters['veranstaltungsstatus.bezeichnung']

      params.filterCount = this.setQueryParam(this.filters.count);

      return params;
    },
    veranstaltungsstati: {
      get() {
        return this.$store.state.veranstaltung.veranstaltungsstati;
      },
    },
    periode: {
      get() {
        return this.$store.state.veranstaltung.perioden;
      },
    },
  },

  created() {
    if (this.veranstaltungsstati.length == 0)
      this.$store.dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`);

    this.initFilter("faecher-liste", "veranstaltungen/", true);

    Api.get("geschaeftspartner/", {
      params: {
        rollen: this.$CONST("ROLLEN").DOZENTEN.join(),
        count: 1000,
      },
    }).then((response) => {
      this.verantwortliche = response.data.sort(function (a, b) {
        return a.personName > b.personName;
      });
    });
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
  },
  methods: {
    refresh() {
      this.resetLoading(this);
    },
    openFach(fach) {
      this.$router.push({
        name: "fach",
        params: { fachid: fach.id, anzeige: 0 },
      });
    },

    setFilterVerantwortlicher(person) {
      this.$set(this.filters, "verantwortlicher", person);
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "fach-loeschen-modal");
    },

    fachloeschen() {
      this.delete("veranstaltungen/", this.selectedIds);
    },
  },
};
</script>

<style></style>
