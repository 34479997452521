<template>
  <div class="col-xl-12 pr-4">
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-12">
              <div class="row">
                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      :readonly="!editable"
                      v-model.trim="mail.empfaenger"
                      class="form-control"
                      placeholder=" "
                    />
                    <label>{{ $t("global.receiveremail") }}</label>
                  </div>
                </div>
                <div class="col-xl-2">
                  <div class="form-group">
                    <input
                      :readonly="!editable"
                      v-if="mail.absenderadresse && lang"
                      v-model.trim="mail.absenderadresse[lang.kuerzel].text"
                      class="form-control"
                      placeholder=" "
                    />
                    <label>{{ $t("global.senderemail") }}</label>
                  </div>
                </div>
                <div class="col-xl-2">
                  <div class="form-group">
                    <input
                      :readonly="!editable"
                      v-if="mail.antwortan && lang"
                      v-model.trim="mail.antwortan[lang.kuerzel].text"
                      class="form-control"
                      placeholder=" "
                    />
                    <label>{{ $t("global.answerto") }}</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12">
                  <div class="form-group">
                    <ValidationProvider
                      :rules="mail.defaultsprache == lang.id ? 'required' : ''"
                      immediate
                      v-slot="{ passed }"
                    >
                      <input
                        :readonly="!editable"
                        v-if="mail.translation && lang"
                        v-model.trim="mail.translation[lang.kuerzel].betreff"
                        v-bind:class="{ 'border-danger': !passed }"
                        class="form-control"
                        placeholder=" "
                      />
                      <label v-bind:class="{ 'text-danger': !passed }">
                        {{ $t("global.subject") }}
                      </label>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-if="mail.translation && lang">
            <div class="col-xl-12">
              <ValidationProvider
                :rules="mail.defaultsprache == lang.id ? 'required' : ''"
                immediate
                v-slot="{ passed }"
              >
                <div
                  class="form-group"
                  :style="{
                    border: passed ? '' : '1px solid red',
                    'padding-top': passed ? '' : '0',
                    'margin-top': passed ? '' : '16px',
                  }"
                >
                  <ckeditor
                    :disabled="!editable"
                    :editor="editor"
                    v-model="mail.translation[lang.kuerzel].text"
                    @blur="highlightTextcodes"
                  ></ckeditor>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";

//import page from "@/mixins/Page";
import ClassicEditor from "/custom_ckeditor/build/ckeditor";

export default {
  name: "MailVorlageInhalt",
  components: {},
  mixins: [],
  store,
  props: {
    mail: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    /*     navbarTitel: {
      type: String,
      required: false,
    }, */
    lang: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      //inhalt: "<p>Content of the editor.</p>",
      toggleOpen: false,
    };
  },
  computed: {},
  watch: {},

  created() {},

  mounted: function () {},

  methods: {
    valueRequired() {},
    highlightTextcodes(event, editor) {
      const pattern = /(@\[([^\]]+)\])|(\{([^}]+)\})/g;

      editor.setData(
        editor.getData().replace(pattern, (match, p1, p2, p3, p4) => {
          const highlightedText = p1 || p3;
          return highlightedText
            ? `<span style="color:#5299e0;">${highlightedText}</span>`
            : match;
        })
      );
    },
  },
};
</script>

<style></style>
