var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xl-12 pr-4"},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-xl-12 block br-t-l-0"},[_c('div',{staticClass:"eingabe-panel-kopf p-3"},[(_vm.mail.gesendet || _vm.mail.testmail_gesendet)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.mail.gesendet),expression:"mail.gesendet",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"readonly":"","placeholder":" "},domProps:{"value":(_vm.mail.gesendet)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.mail, "gesendet", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.sent")))])])]),_c('div',{staticClass:"col-xl-4"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.mail.testmail_gesendet),expression:"mail.testmail_gesendet",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"readonly":"","placeholder":" "},domProps:{"value":(_vm.mail.testmail_gesendet)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.mail, "testmail_gesendet", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.testmailsent")))])])])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-3"},[_c('div',{staticClass:"form-group"},[(!_vm.mail.isVorlage)?_c('div',[_c('ValidationProvider',{attrs:{"rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.mail.absendermail),expression:"mail.absendermail",modifiers:{"trim":true}}],staticClass:"form-control",class:{ 'border-danger': !passed },attrs:{"readonly":!_vm.editable,"placeholder":" "},domProps:{"value":(_vm.mail.absendermail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.mail, "absendermail", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{class:{ 'text-danger': !passed }},[_vm._v(_vm._s(_vm.$t("global.senderemail")))])]}}],null,false,599667964)})],1):_vm._e(),(_vm.mail.isVorlage && _vm.mail.absenderadresse && _vm.lang)?_c('div',[_c('ValidationProvider',{attrs:{"rules":_vm.mail.defaultSprache == _vm.lang.id ? 'required' : '',"immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.mail.absenderadresse[_vm.lang.kuerzel].text),expression:"mail.absenderadresse[lang.kuerzel].text",modifiers:{"trim":true}}],staticClass:"form-control",class:{ 'border-danger': !passed },attrs:{"readonly":!_vm.editable,"placeholder":" "},domProps:{"value":(_vm.mail.absenderadresse[_vm.lang.kuerzel].text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.mail.absenderadresse[_vm.lang.kuerzel], "text", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{class:{ 'text-danger': !passed }},[_vm._v(_vm._s(_vm.$t("global.senderemail")))])]}}],null,false,1640407004)})],1):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-2"},[_c('div',{staticClass:"form-group"},[_c('date-picker',{attrs:{"date-format":"dd.mm.yy","initial":_vm.mail.zeitpunkt,"disabled":!_vm.editable,"placeholder":" ","show-icon":true},on:{"update":function (val) { return (_vm.mail.zeitpunkt = val); }}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.date")))])],1)]),_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"form-group"},[(!_vm.mail.isVorlage)?_c('div',[_c('ValidationProvider',{attrs:{"rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.mail.betreff),expression:"mail.betreff",modifiers:{"trim":true}}],staticClass:"form-control",class:{ 'border-danger': !passed },attrs:{"readonly":!_vm.editable,"placeholder":" "},domProps:{"value":(_vm.mail.betreff)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.mail, "betreff", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{class:{ 'text-danger': !passed }},[_vm._v(_vm._s(_vm.$t("global.subject")))])]}}],null,false,3039393063)})],1):_vm._e(),(_vm.mail.isVorlage && _vm.mail.translation && _vm.lang)?_c('div',[_c('ValidationProvider',{attrs:{"rules":_vm.mail.defaultSprache == _vm.lang.id ? 'required' : '',"immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.mail.translation[_vm.lang.kuerzel].betreff),expression:"mail.translation[lang.kuerzel].betreff",modifiers:{"trim":true}}],staticClass:"form-control",class:{ 'border-danger': !passed },attrs:{"readonly":!_vm.editable,"placeholder":" "},domProps:{"value":(_vm.mail.translation[_vm.lang.kuerzel].betreff)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.mail.translation[_vm.lang.kuerzel], "betreff", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{class:{ 'text-danger': !passed }},[_vm._v(_vm._s(_vm.$t("global.subject")))])]}}],null,false,1171808903)})],1):_vm._e()])]),_c('div',{staticClass:"col-xl-4"},[_c('div',{staticClass:"form-group"},[_c('v-select',{attrs:{"label":"bezeichnung","options":_vm.mailbanner,"disabled":!_vm.editable,"placeholder":" ","clearable":true},on:{"input":_vm.mailBannerUpdated},model:{value:(_vm.mail.mailbanner),callback:function ($$v) {_vm.$set(_vm.mail, "mailbanner", $$v)},expression:"mail.mailbanner"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t("global.taptosearch")))])]),_c('label',[_vm._v(_vm._s(_vm.$t("global.mailbanner")))])],1)])]),(!_vm.mail.isVorlage)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('ValidationProvider',{attrs:{"rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
return [_c('div',{staticClass:"form-group",style:({
                  border: passed ? '' : '1px solid red',
                  'padding-top': passed ? '' : '0',
                  'margin-top': passed ? '' : '16px',
                })},[_c('ckeditor',{attrs:{"editor":_vm.editor,"disabled":!_vm.editable},on:{"blur":_vm.highlightTextcodes},model:{value:(_vm.mail.inhalt),callback:function ($$v) {_vm.$set(_vm.mail, "inhalt", $$v)},expression:"mail.inhalt"}})],1)]}}],null,false,585365832)})],1)]):_vm._e(),(_vm.mail.isVorlage && _vm.mail.translation && _vm.lang)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('ValidationProvider',{attrs:{"rules":_vm.mail.defaultSprache == _vm.lang.id ? 'required' : '',"immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
return [_c('div',{staticClass:"form-group",style:({
                  border: passed ? '' : '1px solid red',
                  'padding-top': passed ? '' : '0',
                  'margin-top': passed ? '' : '16px',
                })},[_c('ckeditor',{attrs:{"editor":_vm.editor,"disabled":!_vm.editable},on:{"blur":_vm.highlightTextcodes},model:{value:(_vm.mail.translation[_vm.lang.kuerzel].text),callback:function ($$v) {_vm.$set(_vm.mail.translation[_vm.lang.kuerzel], "text", $$v)},expression:"mail.translation[lang.kuerzel].text"}})],1)]}}],null,false,3613280784)})],1)]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }